
.main{
        background-color: #e3e5eb;

}
.title{
        padding: 1rem;
        margin-left: 2rem;
         /* background-color: #EBECF0; */

}
.sideBarLayout{
        margin: 1rem;
        /* background-color: lightgray; */
}
.siderBar{
  background-color: #EBECF0;

}
Button{
        margin-bottom: 1rem;
        
}
.tableRow{
        background-color: #F7F7F7;
        font-family: Roboto;
}
.ant-btn{
        background-color: white;
        min-width:24px;
}
/* .ant-btn:active{
        background-color:#E2F2FF;

} */


.ant-btn:focus {
     
        color:#0086FF;
}
.associateName{
        font-weight: bold;
}
.ant-picker-input>input{
        color: #0086FF;
        font: normal normal medium 16px/21px Roboto;
    }
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        font:normal normal bold 16px/32px Roboto;
        color:#111111;
        opacity: 0.7;
    }
    