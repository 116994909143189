.scroll::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

.scroll::-webkit-scrollbar-track {
  /* background: #E9F4F8;        color of the tracking area */
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #A0A0A0;   /* color of the scroll thumb */

  border-radius: 20px;       /*roundness of the scroll thumb*/
  /* border: 1px solid #b5dbe8;  creates padding around scroll thumb */

}
/* .ant-table-cell{
  text-align:left;
  border: 1px solid;
} */