.tableRow{
    background-color: #F7F7F7;
    cursor: pointer;
    font-family:  Roboto;

}
/* .tableRow >*{
    border: 1px solid red;
    text-align: left;
} */
.ant-table-thead{
    color: #696969;
    opacity: 0.7;
    font:'normal normal normal 16px/21px Roboto';
}

.ant-table-pagination-right >*{
   
    margin-left: 5px;

}
.btnSearch{
    background-color: white;
    width:24px;
    margin-left: 7rem;
}
.empName{
    font-weight: bold;
}
.associateName{
    font-weight: bold;

}
.questionBgClr{
    background-color:#FAFAFA;
    
}
.ant-picker-input>input{
    color: #0086FF;
    font: normal normal medium 16px/21px Roboto;
}
