@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,500;0,700;1,400&display=swap');

.titley{
    border: 1px solid rgb(32, 31, 31);
    transform: rotate(370deg);
    -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
    height: 200;
    

}
